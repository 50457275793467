<template>
	<section class="section-wrapper">
		<base-heading :heading="`${prefix}.title`" :subheading="`${prefix}.subtitle`" v-animate="`slideLeft`"/>
		<div class="colors-wrapper" v-animate="`opacity`">
            <template v-if="value.color_type == 'colors'">
                <div v-for="(item, index) in value.colors" :key="index" class="color" :style="{'background': item.color_code}">
                    <base-font variant="small-heading" color="white" class="color-content">
                        {{item.color_name}}
                        <br>
                        {{item.alt_code}}
                    </base-font>
                </div>
            </template>
            <template v-else>
                <div v-for="(item, index) in value.foil" :key="index" class="color foils" v-background="item.foil_image.path">
                    <base-font variant="small-heading" color="white" class="color-content">
                        {{item.foil_name}}
                    </base-font>
                </div>
            </template>
			<div class="colors-info">
				<base-font tag="div" color="white" size="md" uppercase marginReset :html="value.info"/>
			</div>
		</div>
	</section>
</template>
<script>
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'

export default {
	components: { BaseHeading },
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
    padding: 4rem 0 0 0;
    @include media-breakpoint-up(xl) {
        padding: 0;
    }
}
.colors-wrapper {
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: column-reverse;
	min-height: 600px;
	@include media-breakpoint-up(md) {
		min-height: 120px;
		flex-direction: row;
	}
	.color {
		flex-grow: 1;
		position: relative;
		transition: .3s ease-in;
        background-size: 100% 100%;
		::v-deep &-content {
			position: absolute;
			opacity: 0;
			top: 50%;
			left: 50%;
			min-width: 185px;
			transition: .1s ease-in;
			text-align: center;
			transform: translate(-50%, -30%);
			font-size: 1.2rem;
			@include media-breakpoint-up(lg) {
				font-size: 1.6rem;
			}
		}
		&:hover {
			flex-grow: 7;
			::v-deep .color-content {
				opacity: 1;
				transition: .3s ease-in;
				transform: translate(-50%, -50%);
				transition-delay: .3s;
			}
		}
	}
    .foils {
        &:hover {
            flex-grow: 4;
        }
    }
}
.colors-info{
	background: #CDBA88;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 2rem 5rem;
	width: 100%;
	@include media-breakpoint-up(md) {
		padding: 0 6.5rem;
		width: 50%;
	}
	@include media-breakpoint-up(lg) {
		width: 30%;
	}
	::v-deep strong {
		color: $white;
	}
}
</style>
